<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >Modo tesouraria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Contas a Receber</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">
              <section class="fd-app-welcome text-left">
                  <h2><b>Relatório Contas a Receber</b></h2>
                  <img
                    class="mb-3"
                    :src="require('@/assets/images/form-effect-01.png')"
                    style="max-height: 16px"
                  >
                </section>
                <div class="d-flex">
                  <div class="align-self-start mr-auto mb-2">
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.idInvoice"
                      @click="fastRecebiveisRel.filtroEscolhido.idInvoice = '', getRecebiveis()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Invoice: {{ this.fastRecebiveisRel.filtroEscolhido.idInvoice }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.idMatriculador "
                      @click="fastRecebiveisRel.filtroEscolhido.idMatriculador = '', fastRecebiveisRel.filtros.usuarioGerou = '', getRecebiveis()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matriculado por: {{ this.fastRecebiveisRel.filtros.usuarioGerou }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.nomeAluno"
                      @click="fastRecebiveisRel.filtroEscolhido.nomeAluno = '', getRecebiveis()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Aluno: {{ this.fastRecebiveisRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.nomeResponsavel"
                      @click="fastRecebiveisRel.filtroEscolhido.nomeResponsavel = '', getRecebiveis()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Resp.: {{ this.fastRecebiveisRel.filtroEscolhido.nomeResponsavel }} <b-icon icon="x-lg"
                        font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.statusPago"
                      @click="fastRecebiveisRel.filtros.status = '', alteraFiltroStatus(), getRecebiveis()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Pago </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.statusCancelado"
                      @click="fastRecebiveisRel.filtros.status = '', alteraFiltroStatus(), getRecebiveis()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Cancelado </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.statusVencido"
                      @click="fastRecebiveisRel.filtros.status = '', alteraFiltroStatus(), getRecebiveis()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Vencido </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRecebiveisRel.filtroEscolhido.statusPendente"
                      @click="fastRecebiveisRel.filtros.status = '', alteraFiltroStatus(), getRecebiveis()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Pendente </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                  </div>
                </div>
              <section>
                <div class="row">
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                    <select
                      class="form-control form-control-sm"
                      v-model="fastRecebiveisRel.filtroEscolhido.idPlataforma"
                     >
                      <option>Todas</option>
                      <option v-for="(plataforma, index) in fastRecebiveisRel.filtros.plataformas" :key="index"
                        :value="plataforma.id_plataforma">
                        {{ plataforma.nome_plataforma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Invoice:</small></label>
                    <input
                    type="text"
                    v-model="fastRecebiveisRel.filtroEscolhido.idInvoice"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Status:</small></label>
                    <select
                    :class="fastRecebiveisRel.filtros.status ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                    v-model="fastRecebiveisRel.filtros.status" @change="alteraFiltroStatus()">
                    <option value="">Todos</option>
                    <option value="P">
                      Pago
                    </option>
                    <option value="A">
                      Pendente
                    </option>
                    <option value="V">
                      Vencido
                    </option>
                    <option value="C">
                      Cancelado
                    </option>
                  </select>
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                    <input
                    type="text"
                    v-model="fastRecebiveisRel.filtroEscolhido.nomeAluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Responsável:</small></label>
                    <input
                    type="text"
                    v-model="fastRecebiveisRel.filtroEscolhido.nomeResponsavel"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Matriculador:</small></label>
                    <div style="position: relative">
                      <input 
                        :class="fastRecebiveisRel.filtros.usuarioGerou.length ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                        v-model="fastRecebiveisRel.filtros.usuarioGerou" 
                        type="text" 
                        :list="fastRecebiveisRel.filtros.datalistId"
                        @input="filtraPessoaSugestao('usuarioGerou', 'usuariosGerou')"
                        @keyup.esc="selecionaPessoaSugerida('')"
                      >
                      <ul v-if="fastRecebiveisRel.filtros.usuariosGerou.length" class="sugestao-lista">
                        <li 
                        class="sugestao-item"
                        v-for="usuario in fastRecebiveisRel.filtros.usuariosGerou" 
                        :key="usuario.id_usuario" 
                        @click="selecionaPessoaSugerida(usuario)">
                          {{ usuario.nome_razao }}
                        </li>
                      </ul>
                    </div>
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-primary mb-1 mt-2 mx-sm-1 mx-md-1 row">
                      <small class="text-primary ml-0 pl-0 col-8">Data Emissão:</small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRecebiveisRel.filtroEscolhido.dataMatriculaStart || fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd" 
                      @click="fastRecebiveisRel.filtroEscolhido.dataMatriculaStart = '', fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row mx-sm-1 mx-md-1">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataMatriculaStart, fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="
                      validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataMatriculaStart, fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataMatriculaStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data inicial"
                      size="sm"
                      placeholder="Data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataMatriculaStart, fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="
                      validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataMatriculaStart, fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data final"
                      size="sm"
                      placeholder="Data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 mx-sm-1 mx-md-1 row">
                      <small class="text-primary ml-0 pl-0 col-8">Data Recebimento:</small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart || fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd" 
                      @click="fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart = '', fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row mx-sm-1 mx-md-1">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart, fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart, fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data inicial"
                      size="sm"
                      placeholder="Data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart, fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart, fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data final"
                      size="sm"
                      placeholder="Data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 mx-sm-1 mx-md-1 row">
                      <small class="text-primary ml-0 pl-0 col-8">Data Cancelamento:</small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart || fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd" 
                      @click="fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart = '', fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row mx-sm-1 mx-md-1">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart, fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart, fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data inicial"
                      size="sm"
                      placeholder="Data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart, fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart, fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd, true), 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', 
                      fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      v-model="fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data final"
                      size="sm"
                      placeholder="Data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                  </form>

                </div>
                <div class="row">
                  <div class="text-left col-lg-4 col-md-12 col-sm-12 mt-4 mb-3">
                      <label class="text-secondary mb-0 mx-1 row">
                      <small class="pl-0 ml-0 pl-0 col-8"><b>Vencimento:</b></small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRecebiveisRel.filtroEscolhido.dataVencimentoStart || fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd" 
                      @click="fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = '', fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                      <div class="d-flex">
                        <b-form-datepicker 
                        class="align-self-start"
                        size="sm"
                        v-model="fastRecebiveisRel.filtroEscolhido.dataVencimentoStart" 
                        @input="getRecebiveis()"
                        locale="pt-BR"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                        label-no-date-selected="Selecionar data"
                        placeholder="Selecionar data"
                        label-help="Use o cursor para selecionar"
                        ></b-form-datepicker>
                        <small class="align-self-start pt-1 px-2">até</small>
                        <b-form-datepicker 
                        class="align-self-start"
                        size="sm"
                        v-model="fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd"
                        @input="getRecebiveis()"
                        locale="pt-BR"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                        label-no-date-selected="Selecionar data"
                        placeholder="Selecionar data"
                        label-help="Use o cursor para selecionar"
                        ></b-form-datepicker>
                      </div>              
                  </div>
                  <div class="text-right col-lg-8 col-md-12 col-sm-12 mb-3 mt-5">
                    <b-button @click="exportaPlanilha()" variant="outline-primary btn-sm text-capitalize rounded mr-3">Exportar</b-button>  
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getRecebiveisRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

                <div class="row">
                  <div class="col-12 table-responsive">
                    <!--tabela oculta sem paginação para exportar-->
                    <table id="relatorio" class="table d-none">
                      <thead>
                        <tr>
                          <th>
                            <small>Vencimento</small>
                          </th>
                          <th>
                            <small>Recebimento</small>
                          </th>
                          <th>
                            <small>Dias de Atraso</small>
                          </th>
                          <th>
                            <small>Invoice</small>
                          </th>
                          <th>
                            <small>Parcela</small>
                          </th>
                          <th>
                            <small>Aluno</small>
                          </th>
                          <th>
                            <small>Valor Parcela</small>
                          </th>
                          <th>
                            <small>Valor Recebido</small>
                          </th>
                          <th>
                            <small>Status</small>
                          </th>
                          <th>
                            <small>Boleto</small>
                          </th>
                          <th>
                            <small>Log</small>
                          </th>
                          <th>
                            <small>Ações</small>
                          </th>
                        </tr>
                      </thead>
                  <tbody>
                    <tr
                      v-for="(e, index) in fastRecebiveisRel.filtro"
                      :key="index"
                    >
                      <td>
                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                      </td>
                      <td>
                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                      </td>
                      <td>
                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                        <small v-else>-</small>                            
                      </td>
                      <td>
                          <small>{{ e.id_invoice }}</small>                               
                      </td>
                      <td>
                        <small> {{ e.parcela }}/{{ e.nr_parcelas }} </small>                             
                      </td>
                      <td>
                        <small> {{ e.nome_aluno }} </small>                                 
                      </td>
                      <td>
                        <small>{{ formataPreco(e.valor_parcela) }}</small>                                  
                      </td>
                      <td>
                        <small v-if="e.valor_pago && e.pago">{{ formataPreco(e.valor_pago) }}</small> 
                        <small v-else>{{ formataPreco(0)}} </small>                                 
                      </td>
                      <td>
                        <span v-if="e.cancelado"><small>Cancelado</small> </span>
                        <span v-else-if="e.pago"><small>Pago</small> </span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small>Vencido</small></span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()"><small>Pendente</small> </span>
                      </td>
                      <td>
                        <small v-if="e.path_boleto">{{ e.path_boleto }}</small>
                        <small v-else>-</small>
                      </td>
                      <td>
                        <small v-if="e.log_pagto_auditoria">{{ e.log_pagto_auditoria }}</small>
                        <small v-else>-</small>
                      </td>
                    </tr>
                  </tbody>
                    </table>
                    <table class="table table-sm text-nowrap table-hover">
                      <thead class="thead-dark" v-if="fastRecebiveisRel.loading">
                        <tr>
                          <th
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </th>
                        </tr>
                      </thead>
                      <thead class="thead-dark" v-if="!fastRecebiveisRel.loading">
                        <tr>
                          <th class="pl-3 pt-2">
                            <b-form-checkbox v-model="fastRecebiveisRel.selectAll"/>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.vencimento !== 0 }" @click="orderByColumn('vencimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Vencimento&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.vencimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.vencimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.recebimento !== 0 }" @click="orderByColumn('recebimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Recebimento&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.recebimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.recebimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.dias_de_atraso !== 0 }" @click="orderByColumn('dias_de_atraso')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Dias de Atraso&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.dias_de_atraso === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.dias_de_atraso === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.invoice !== 0 }" @click="orderByColumn('invoice')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Invoice&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.invoice === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.invoice === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Parcela
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.aluno !== 0 }" @click="orderByColumn('aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Aluno&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.valor_parcela !== 0 }" @click="orderByColumn('valor_parcela')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Parcela&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_parcela === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_parcela === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.valor_recebido !== 0 }" @click="orderByColumn('valor_recebido')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Recebido&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_recebido === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_recebido === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRecebiveisRel.orderBy.status !== 0 }" @click="orderByColumn('status')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Status&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.status === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRecebiveisRel.orderBy.status === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Boleto
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Log
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Ações
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastRecebiveisRel.loading">
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                      <b-icon icon="gear-fill" animation="spin"/> Carregando 
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastRecebiveisRel.paginacao.length">
                    <tr
                      v-for="(e, index) in fastRecebiveisRel.paginacao"
                      :key="index"
                    >
                      <td class="pl-3 pt-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <b-form-checkbox role="button" type="checkbox" v-model="fastRecebiveisRel.selectedItems" :value="e.id_invoice + '-' + e.parcela"/>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}" 
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                        <small v-else>-</small>                            
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"  
                      >
                          <small>{{ e.id_invoice }}</small>                               
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ e.parcela }}/{{ e.nr_parcelas }}</small>                             
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ e.nome_aluno }}</small>                                 
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>R$ {{ formataPreco(e.valor_parcela) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small v-if="e.valor_pago && e.pago">R$ {{ formataPreco(e.valor_pago) }}</small> 
                        <small v-else>R$ 0,00</small>                                 
                      </td>
                      <td class="text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <span v-if="e.cancelado" class="text-secondary"><small>Cancelado</small> </span>
                        <span v-else-if="e.pago" class="text-success"><small>Pago</small> </span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small class="text-danger">Vencido</small></span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()" class="text-primary"><small>Pendente</small> </span>
                      </td>
                      <td class="align-middle text-center pl-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <a title="ABRIR BOLETO" :href="e.path_boleto" target="_blank" class="text-dark mr-2"><b-icon icon="file-earmark-pdf" font-scale="1" ></b-icon></a>
                        <a title="COPIAR URL BOLETO" href="#" @click.prevent="copyURLBoleto(e.path_boleto); exibeToasty('Boleto copiado para a Área de Transferência!','success')" target="_blank" class="text-dark"><b-icon icon="back" font-scale="1"></b-icon></a>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <a v-if="e.log_pagto_auditoria" role="button" title="LOG PAGAMENTO" @click.prevent="exibeModal(e, 'log_pagto')" class="text-primary"><u>Ver</u></a>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <b-dropdown size="sm" variant="light rounded">
                          <b-dropdown-item @click.prevent="exibeDetalhes(e)">Detalhes</b-dropdown-item>
                          <b-dropdown-item @click.prevent="exibeRenegociacao(e)">Renegociação</b-dropdown-item>
                          <b-dropdown-item @click.prevent="exibeModal(e, 'comissoes')">Comissões</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                        Nenhuma parcela encontrada
                      </td>
                    </tr>
                  </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right mt-4">
                      <div class="d-inline">
                        <h6 class="font-weight-bold d-inline">Valor Total: </h6>
                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalParcelaSelected) }}</b-badge></h5>
                      </div>
                      <div class="my-2">
                        <h6 class="font-weight-bold d-inline">Valor Recebido: </h6>
                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalParcelaPagoSelected) }}</b-badge></h5>
                      </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="20"
                  :items="fastRecebiveisRel.filtro"
                  @changePage="fastRecebiveisRel.paginacao = $event"
                />
              </div>
                </div>
            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->
          
          <!-- Detalhes - Ações -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirDetalhes">
              <DetalhesRecebivel 
                :id_plataforma="fastInvoiceFiltrosDetalhes.idPlataforma" 
                :id_invoice="fastInvoiceFiltrosDetalhes.idInvoice" 
                :parcela="fastInvoiceFiltrosDetalhes.parcela"
                tipo="detalhes"
              />
            </div>
          </transition>


          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRenegociacao">
              <RenegociacaoDividas
              :id_plataforma="fastRenegociacaoDividas.id_plataforma"
              :id_invoice="fastRenegociacaoDividas.id_invoice" 
              :dados_invoice="fastRenegociacaoDividas"
              />
            </div>
          </transition>
          <!-- /Detalhes - Ações -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
            <a
              v-if="fastNavegacao.exibirDetalhes"
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="alteraFastNavegacao('exibirRelatorio')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Log / Motivo</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastRecebiveisRel.modal.logRecebivel }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>

        <modal
          name="modalDetalhesComissao"
          :scrollable="true"
          height="auto"
          width="950px"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Invoice - Schema Comissões</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalDetalhesComissao')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div v-if="fastRecebiveisRel.modal.detalhesComissao.length" class="row">
                <div class="col-12 mt-1 mb-4 table-responsive">
                  <h6 class="text-primary mb-3">Invoice: {{ fastRecebiveisRel.modal.detalhesComissao[0].id_invoice }}</h6>
                  <table class="table text-nowrap text-center">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col"><small class="font-weight-bold">Nome</small></th>
                        <th scope="col"><small class="font-weight-bold">Parcela</small></th>
                        <th scope="col"><small class="font-weight-bold">Valor Parcela</small></th>
                        <th scope="col"><small class="font-weight-bold">Pago</small></th>
                        <th scope="col"><small class="font-weight-bold">Valor Comissão</small></th>
                        <th scope="col"><small class="font-weight-bold">Percentual Comissão</small></th>
                        <th scope="col"><small class="font-weight-bold">Comissão por Valor</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(e, index) in fastRecebiveisRel.modal.detalhesComissaoPaginacao" :key="index">
                        <td><small>{{e.nome_razao}} {{ e.sobrenome_fantasia }}</small></td>
                        <td><small>{{e.parcela}}</small></td>
                        <td><small>R$ {{formataPreco(e.valor_parcela)}}</small></td>
                        <td><small v-if="e.pago" class="text-success">Sim</small><small v-else class="text-danger">Não</small></td>
                        <td><small>R$ {{formataPreco(e.valorComissao)}}</small></td>
                        <td><small>{{e.ComissaoPorPercentual}}</small></td>
                        <td><small>R$ {{formataPreco(e.ComissaoPorValor)}}</small></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-right mt-0 mb-2">
                  <span class="pr-2"><b-badge variant="secondary">Valor Comissões: R$ {{ formataPreco(fastRecebiveisRel.modal.totalComissoes.total) }}</b-badge></span>
                  <span class="pr-2"><b-badge variant="success">Comissões Pagas: R$ {{ formataPreco(fastRecebiveisRel.modal.totalComissoes.pago) }}</b-badge></span>
                  <span class="pr-2"><b-badge variant="primary">Comissões Pendentes: R$ {{ formataPreco(fastRecebiveisRel.modal.totalComissoes.total - fastRecebiveisRel.modal.totalComissoes.pago) }}</b-badge></span>
                </div>
                <div class="col-12 text-center mt-2">
                  <Pagination
                    :page-size="6"
                    :items="fastRecebiveisRel.modal.detalhesComissao"
                    @changePage="fastRecebiveisRel.modal.detalhesComissaoPaginacao = $event"
                  />
                </div>
              </div>
              <div v-else>
                <h6 class="text-danger text-center">Não há comissões para este Invoice.</h6>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalDetalhesComissao'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  import Pagination from "../components/Pagination";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import RenegociacaoDividas from "../components/RenegociacaoDividas.vue";
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosContasReceber",
    components: {
      Pagination,
      DetalhesRecebivel,
      RenegociacaoDividas
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
          exibirDetalhes : false,
          exibirRenegociacao : false,
        },
        fastRecebiveisRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPlataforma: this.$route.params.id_plataforma,
            nomeAluno: '',
            nomeResponsavel: '',
            idInvoice: '',
            idMatriculador: '',
            dataMatriculaStart: '',
            dataMatriculaEnd: '',
            dataVencimentoStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataVencimentoEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            dataRecebimentoStart: '',
            dataRecebimentoEnd: '',
            dataCancelamentoStart: '',
            dataCancelamentoEnd: '',
            statusVencido: '',
            statusPago: '',
            statusPendente: '',
            statusCancelado: ''
          },
          filtros : {
            plataformas: [],
            status: '',
            usuariosGerou : [],
            usuariosCancelou : [],
            usuarioGerou: '',
            usuarioCancelou: '',
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000)
          },
          modal : {
            logRecebivel: '',
            detalhesComissao: [],
            detalhesComissaoPaginacao: [],
            totalComissoes: {
              total: '',
              pago: ''
            }
          },
          gatewaysPagamento: [],
          orderBy: {
            plataforma: 0, 
            vencimento: 0, 
            recebimento: 0, 
            dias_de_atraso: 0, 
            invoice: 0, 
            parcela: 0, 
            aluno: 0, 
            valor_parcela: 0, 
            valor_recebido: 0, 
            status: 0, 
            boleto: 0, 
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        tableData : [],
        fastRenegociacaoDividas: {
          id_plataforma: 0,
          id_invoice: 0,
        }
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getGateways()
                this.getRecebiveisRel(this.$route.params.id_plataforma)
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalParcelaSelected() {
        return this.fastRecebiveisRel.filtro.reduce((total, item) => {
          if (!this.fastRecebiveisRel.selectedItems.length){
            return total + item.valor_parcela
          }
          if (this.fastRecebiveisRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_parcela
          } else {
            return total
          }
        }, 0)
      },
      totalParcelaPagoSelected() {
        return this.fastRecebiveisRel.filtro.reduce((total, item) => {
          if (!this.fastRecebiveisRel.selectedItems.length){
            return item.pago ? (total + item.valor_pago) : total
          }
          if (this.fastRecebiveisRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return item.pago ? (total + item.valor_pago) : total
          } else {
            return total
          }
        }, 0)
      }
    },
    watch: {
      'fastRecebiveisRel.selectAll'(val) {
        if (val) {
          this.fastRecebiveisRel.selectedItems = this.fastRecebiveisRel.filtro.map((item) => item.id_invoice + "-" + item.parcela)
        } else {
          this.fastRecebiveisRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.fastRecebiveisRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastRecebiveisRel.filtros.plataformas = res.Data;
        } else{
          this.fastRecebiveisRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      getGateways(){
        this.promiseGetFastApi("api/fast_tesouraria_gateway/lista_todos", `id_plataforma=${this.fastRecebiveisRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {                
        if (res.length) {
          this.fastRecebiveisRel.gatewaysPagamento = res;
        }else{
          this.fastRecebiveisRel.gatewaysPagamento = [];
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      async getRecebiveisRel(){       
        this.fastRecebiveisRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastRecebiveisRel.filtroEscolhido.idPlataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastRecebiveisRel.filtroEscolhido.idPlataforma;

        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber", `IdPlataforma=${plataforma}&NomeAluno=${this.fastRecebiveisRel.filtroEscolhido.nomeAluno}&NomeResponsavel=${this.fastRecebiveisRel.filtroEscolhido.nomeResponsavel}&IdInvoice=${this.fastRecebiveisRel.filtroEscolhido.idInvoice}&DataMatriculaStart=${this.fastRecebiveisRel.filtroEscolhido.dataMatriculaStart}&DataMatriculaEnd=${this.fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd}&DataVencimentoStart=${this.fastRecebiveisRel.filtroEscolhido.dataVencimentoStart}&DataVencimentoEnd=${this.fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd}&DataRecebimentoStart=${this.fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart}&DataRecebimentoEnd=${this.fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd}&DataCancelamentoStart=${this.fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart}&DataCancelamentoEnd=${this.fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd}&StatusVencido=${this.fastRecebiveisRel.filtroEscolhido.statusVencido}&StatusPago=${this.fastRecebiveisRel.filtroEscolhido.statusPago}&StatusPendente=${this.fastRecebiveisRel.filtroEscolhido.statusPendente}&StatusCancelado=${this.fastRecebiveisRel.filtroEscolhido.statusCancelado}&IdMatriculador=${this.fastRecebiveisRel.filtroEscolhido.idMatriculador}`)

        .then(res => {
          console.log("getRecebiveisRel", res)
          if (res.length) {
            this.fastRecebiveisRel.filtro = todasPlataformasFilhas ? res : res.filter(item => item.id_plataforma == this.fastRecebiveisRel.filtroEscolhido.idPlataforma);
          }
          else {
            this.fastRecebiveisRel.filtro = []
          }
  
          this.fastRecebiveisRel.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastRecebiveisRel.loading = false
        })
      },
      alteraFiltroStatus(){
        if(this.fastRecebiveisRel.filtros.status == 'P'){
          this.fastRecebiveisRel.filtroEscolhido.statusCancelado = '';
          this.fastRecebiveisRel.filtroEscolhido.statusVencido = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPendente = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPago = true;
        }
        if(this.fastRecebiveisRel.filtros.status == 'A'){
          this.fastRecebiveisRel.filtroEscolhido.statusCancelado = '';
          this.fastRecebiveisRel.filtroEscolhido.statusVencido = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPago = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPendente = true;
        }
        if(this.fastRecebiveisRel.filtros.status == 'V'){
          this.fastRecebiveisRel.filtroEscolhido.statusCancelado = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPendente = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPago = '';
          this.fastRecebiveisRel.filtroEscolhido.statusVencido = true;
        }
        if(this.fastRecebiveisRel.filtros.status == 'C'){
          this.fastRecebiveisRel.filtroEscolhido.statusVencido = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPendente = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPago = '';
          this.fastRecebiveisRel.filtroEscolhido.statusCancelado = true;
        }
        if(!this.fastRecebiveisRel.filtros.status){
          this.fastRecebiveisRel.filtroEscolhido.statusVencido = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPendente = '';
          this.fastRecebiveisRel.filtroEscolhido.statusPago = '';
          this.fastRecebiveisRel.filtroEscolhido.statusCancelado = '';
        }
      },
      filtraPessoaSugestao(filtro, listagem){
      if (this.fastRecebiveisRel.filtros[filtro].length >= 3) {
        setTimeout(() => {
          this.promiseGetFastApi("api/fast_pessoa/lista_paginado", `PageIndex=1&PageSize=100&id_plataforma=${this.fastRecebiveisRel.filtroEscolhido.idPlataforma}&nome_pessoa=${this.fastRecebiveisRel.filtros[filtro]}&matriculador=true`)
          .then((res) => {          
            if (res.Data.length) {
              this.fastRecebiveisRel.filtros[listagem] = res.Data;
              }
            })
            .catch((e) => {
                console.log(e);
            });
          }, 1000)
          } else {
            this.fastRecebiveisRel.filtros.usuariosGerou = []
        }
      },
      selecionaPessoaSugerida(usuario) {
          this.fastRecebiveisRel.filtros.usuariosGerou = [];
          if(usuario == ''){
            this.fastRecebiveisRel.filtroEscolhido.idUsuarioGerou = '';
            this.fastRecebiveisRel.filtros.usuarioGerou = '';
          }else{
            this.fastRecebiveisRel.filtros.usuarioGerou = usuario.nome_razao;
            this.fastRecebiveisRel.filtroEscolhido.idMatriculador = usuario.id_usuario;
          }
      },
      limpaFiltros(){
        this.fastRecebiveisRel.filtroEscolhido.idPlataforma = this.$route.params.id_plataforma
        this.fastRecebiveisRel.filtroEscolhido.dataMatriculaStart = ''
        this.fastRecebiveisRel.filtroEscolhido.dataMatriculaEnd = ''
        this.fastRecebiveisRel.filtroEscolhido.dataRecebimentoStart = ''
        this.fastRecebiveisRel.filtroEscolhido.dataRecebimentoEnd = ''
        this.fastRecebiveisRel.filtroEscolhido.dataCancelamentoStart = ''
        this.fastRecebiveisRel.filtroEscolhido.dataCancelamentoEnd = ''
        this.fastRecebiveisRel.filtroEscolhido.dataVencimentoStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)
        this.fastRecebiveisRel.filtroEscolhido.dataVencimentoEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
        this.fastRecebiveisRel.filtroEscolhido.nomeAluno = ''
        this.fastRecebiveisRel.filtroEscolhido.nomeResponsavel = ''
        this.fastRecebiveisRel.filtroEscolhido.idInvoice = ''
        this.fastRecebiveisRel.filtroEscolhido.idMatriculador = ''
        this.fastRecebiveisRel.filtroEscolhido.statusPago = ''
        this.fastRecebiveisRel.filtroEscolhido.statusCancelado = ''
        this.fastRecebiveisRel.filtroEscolhido.statusPendente = ''
        this.fastRecebiveisRel.filtroEscolhido.statusVencido = ''
        this.fastRecebiveisRel.filtros.status = ''
        this.fastRecebiveisRel.filtros.usuarioGerou = ''
        this.fastRecebiveisRel.filtros.usuarioCancelou =  ''
        this.fastRecebiveisRel.filtros.usuariosGerou = []
        this.fastRecebiveisRel.filtros.usuariosCancelou = []

        this.getRecebiveisRel();
      },
      calculaDiasAtraso(dataVenc){
        const dataAtual = new Date()
        const dataCalc = new Date(dataVenc)

        const difMs = dataAtual - dataCalc
        const difDias = difMs / 86400000 - 1

        return difDias.toFixed();
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Vencimento', 'Recebimento', 'Dias de Atraso', 'Invoice', 'Parcela', 'Aluno', 'Valor Parcela', 'Valor Recebido', 'Status', 'Boleto', 'Log'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-contas-a-receber.xlsx');
      },
      exibeModal(el, modal){
        if(modal === 'log_pagto'){
          this.fastRecebiveisRel.modal.logRecebivel = el.log_pagto_auditoria
          this.showModal('modalVerLog')
        } 
        else if (modal === 'comissoes'){
          
          this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber_comissao", `IdPlataforma=${el.id_plataforma}&IdInvoice=${el.id_invoice}`)

          .then((res) => {   

              console.log('getComissoes', res)

              this.fastRecebiveisRel.modal.detalhesComissao = res
              
              this.fastRecebiveisRel.modal.totalComissoes.total =  this.fastRecebiveisRel.modal.detalhesComissao.reduce((total, item) => total + Number(item.valorComissao), 0)
              this.fastRecebiveisRel.modal.totalComissoes.pago =  this.fastRecebiveisRel.modal.detalhesComissao.reduce((total, item) =>  {
                if (item.pago) {
                  return total + Number(item.valorComissao);
                } else {
                  return total;
                }
              }, 0)

              this.showModal('modalDetalhesComissao')
              
            })
            .catch((e) => {
                console.log(e);
            });
        }
      },
      exibeDetalhes(e){
        this.fastInvoiceFiltrosDetalhes.idPlataforma = Number(this.fastRecebiveisRel.filtroEscolhido.idPlataforma)
        this.fastInvoiceFiltrosDetalhes.idInvoice = e.id_invoice
        this.fastInvoiceFiltrosDetalhes.parcela = e.parcela

        this.alteraFastNavegacao('exibirDetalhes')
      },
      exibeRenegociacao(e){
        console.log("exiberenegociacao", e);
        this.fastRenegociacaoDividas = e
        this.alteraFastNavegacao('exibirRenegociacao')
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastRecebiveisRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastRecebiveisRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastRecebiveisRel.orderBy[coluna] = this.fastRecebiveisRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastRecebiveisRel.filtro.sort((a, b) => {
          const order = this.fastRecebiveisRel.orderBy[coluna];

          if (coluna === 'plataforma') {
            return (a.plataforma > b.plataforma ? 1 : -1) * order; 
          } else if (coluna === 'vencimento') {
            return (a.data_vencimento > b.data_vencimento ? 1 : -1) * order;
          } else if (coluna === 'recebimento') {
            function ordenarPorData(a, b, order) {
              // Verifica se ambos os valores são nulos ou iguais a "Sem data"
              if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
                return 0;
              }

              // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
              if (!a || a === 'Sem data') {
                return 1 * order;
              }

              if (!b || b === 'Sem data') {
                return -1 * order;
              }

              // Ordena as datas restantes normalmente
              const dataA = new Date(a);
              const dataB = new Date(b);

              return (dataA > dataB ? 1 : -1) * order;
            }

            return ordenarPorData(a.data_pagamento, b.data_pagamento, order);
          } else if (coluna === "dias_de_atraso") {
              const aDias = this.calculaDiasAtraso(a.data_vencimento);
              const bDias = this.calculaDiasAtraso(b.data_vencimento);
              return (aDias - bDias) * order;
          } else if (coluna === 'invoice') {
            return (a.id_invoice > b.id_invoice ? 1 : -1) * order; 
          } else if (coluna === 'aluno') {
            return a.nome_aluno.localeCompare(b.nome_aluno) * order;
          } else if (coluna === 'valor_parcela') {
            return (a.valor_parcela - b.valor_parcela) * order;
          } else if (coluna === 'valor_recebido') {
            return (a.valor_pago - b.valor_pago) * order;
          } else if (coluna === 'status') {
              if (a.cancelado) return -1 * order;
              if (b.cancelado) return 1 * order;
              if (a.pago && !b.pago) return -1 * order;
              if (!a.pago && b.pago) return 1 * order;
              if (new Date(a.data_vencimento) < new Date() && new Date(b.data_vencimento) >= new Date()) return -1 * order;
              if (new Date(b.data_vencimento) < new Date() && new Date(a.data_vencimento) >= new Date()) return 1 * order;
              return 0;
            } else {
              return 0;
          }
        });
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      criaTooltip(e){

        return `<div class="text-left">
                  Aluno: ${e.nome_aluno}<br>
                  Resp.: ${e.nome_responsavel}<br>
                  Data Vencimento: ${this.formataDataT(e.data_vencimento)}<br>
                  Valor: R$ ${this.formataPreco(e.valor_parcela)}<br>
                  Valor Pago: R$ ${e.valor_pago ? this.formataPreco(e.valor_pago) : this.formataPreco(0)}
                </div>`;
      }
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }
  .dropdown-menu{
    font-size: 12px;
  }

  </style>
  